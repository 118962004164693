<template>
  <div id="Team-Manage">
    <div id="Team-Manage-Header">
      <div class="left">团队管理</div>
      <div class="right">更多</div>
    </div>
    <div id="Team-Manage-Init">
      <div class="top">
        <div class="left">指数</div>
        <div class="right" v-if="false">
          <a-button-group>
            <a-button> 周表 </a-button>
            <a-button> 月表 </a-button>
            <a-button> 年表 </a-button>
          </a-button-group>
        </div>
      </div>
      <div class="center">
        <Echart />
      </div>
      <a-row class="bottom" :gutter="[5, 10]">
        <a-col :span="12">
          <div>
            <p>
              <span>我的团队:</span>
              <span>{{ teamInfo.total_user }}人</span>
            </p>
            <p>
              <span>本月新增:</span>
              <span>{{ teamInfo.user_increase }}</span>
            </p>
            <p>
              <span>较上月同比:</span>
              <span>{{ teamInfo.user_change_rate }}</span>
            </p>
          </div>
        </a-col>
        <a-col :span="12">
          <div>
            <p>
              <span>团队算力:</span>
              <span>{{ teamInfo.total_power }}</span>
            </p>
            <p>
              <span>本月新增:</span>
              <span>{{ teamInfo.power_increase }}</span>
            </p>

            <p>
              <span>较上月同比:</span>
              <span>{{ teamInfo.power_change_rate }}</span>
            </p>
          </div>
        </a-col>
      </a-row>
    </div>
    <div id="Team-Manage-List">
      <a-table
        :columns="table.columns"
        :data-source="table.datas"
        :rowKey="(record) => record.id"
        :pagination="false"
      >
        <span slot="real_name" slot-scope="record, index">
          <p>{{ record }}</p>
          <p>{{ index.certificate }}</p>
        </span>
      </a-table>
    </div>
  </div>
</template>

<script>
import Vue from "vue";
import { Table } from "ant-design-vue";
Vue.use(Table);

import Echart from "./echart";

export default {
  props: {},
  // 引入的组件
  components: { Echart },
  data() {
    return {
      teamInfo: {},
      table: {
        columns: [
          {
            title: "姓名/帐号",
            dataIndex: "real_name",
            scopedSlots: { customRender: "real_name" },
          },
          {
            title: "容量",
            dataIndex: "quantity",
            scopedSlots: { customRender: "quantity" },
            align: "right",
          },
        ],
        datas: [],
      },
    };
  },
  methods: {
    async getTeamList() {
      await this.$axios.get("personal-center/team").then((res) => {
        if (res.code === 0) {
          for (let i in res.data.users) res.data.users[i].id = i;
          this.table.datas = res.data.users;
          delete res.data.users;
          this.teamInfo = res.data;
        } else {
          console.log(res.message);
          this.$message.console.error(res.message);
        }
      });
    },
  },
  created() {
    this.getTeamList();
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="less" scoped>
#Team-Manage {
  padding: 0rem !important;
  margin-left: -0.26rem;
  margin-right: -0.26rem;

  #Team-Manage-Header {
    display: flex;
    justify-content: space-between;
    background-color: rgba(61, 68, 123, 1);
    padding-left: 0.4rem;
    padding-right: 0.4rem;
    line-height: 2.6em;

    > .left {
      color: rgba(224, 62, 81, 1);
    }
  }

  #Team-Manage-Init {
    padding: 0.2rem 0.4rem;

    > .top {
      display: flex;
      justify-content: space-between;
      line-height: 3em;

      > .right {
        .ant-btn {
          width: auto;
          color: white;
          border-color: #828ac3;
          background-color: #3d447b;
          height: 2em;
          line-height: 2em;
          padding: 0 0.5em;
        }
      }
    }

    > .center {
      padding: 0.1rem 0;

      > .echarts-exponent {
        height: 4rem;
      }
    }

    > .bottom {
      display: flex;
      justify-content: space-around;
      align-content: center;
      align-items: center;
      margin: auto -1em !important;

      > div {
        > div {
          background-color: rgba(221, 63, 88, 1);
          border-radius: 0.2rem;
          padding: 0.5em 1em;

          > p {
            display: flex;
            justify-content: space-between;
          }
        }

        &:nth-child(2) {
          > div {
            background-color: rgba(119, 213, 201, 1);
          }
        }
      }
    }
  }

  #Team-Manage-List {
    padding: 0 0.3rem;

    > table {
      width: 100%;

      > * {
        > * {
          > *:nth-child(2) {
            text-align: center;
          }

          > *:nth-child(3) {
            text-align: right;
          }
        }
      }

      > thead > tr,
      > tbody > tr:nth-child(even) {
        background-color: rgba(61, 68, 123, 1);

        > th:first-child,
        > td:first-child,
        > th:last-child,
        > td:last-child {
          position: relative;

          &::before {
            content: "";
            position: absolute;
            top: 0;
            width: 100%;
            height: 100%;
            background-color: rgba(61, 68, 123, 1);
          }

          &:first-child::before {
            left: -100%;
          }

          &:last-child::before {
            right: -100%;
          }
        }
      }

      > thead > tr {
        > th {
          line-height: 3em;
        }
      }

      > tbody > tr {
        > td {
          > div {
            line-height: 1.5em;
            padding: 0.6em 0;

            > p:first-child {
              //: 0.3rem;
            }
          }
        }

        > td:nth-child(2),
        > td:nth-child(3) {
          p {
            color: rgba(221, 63, 88, 1);
          }

          img {
            width: 1rem;
          }
        }
      }
    }
  }
}
</style>
