
<template>
  <div id="echarts">
    <!-- echarts 装载标签 -->
    <div ref="echarts"></div>
  </div>
</template>

<script>
import * as echarts from "echarts";
import {
  TooltipComponent,
  GridComponent,
  LegendComponent,
  DataZoomComponent,
} from "echarts/components";
import { CandlestickChart, LineChart } from "echarts/charts";
import { CanvasRenderer } from "echarts/renderers";

echarts.use([
  TooltipComponent,
  GridComponent,
  LegendComponent,
  DataZoomComponent,
  CandlestickChart,
  LineChart,
  CanvasRenderer,
]);

export default {
  data() {
    return {
      echart: {
        chart: {},
        datas: {
          time_quantum: [],
          quantity: [],
        },
      },
    };
  },
  watch: {
    "echarts.sec"() {
      this.getCandlestickData();
    },
  },
  methods: {
    // K线图echarts图形化初始化
    echart_init() {
      /** 判断带有 ref 的标签是否存在
       * 这个 ref 是VUE的东西，获取的是需要装载 echarts 的 dom 元素对象
       * 等于 jq 的 $('#echarts')[0]
       * 等于 js 的 document.getElementById('echarts')
       */
      if (!this.$refs["echarts"]) return;

      //   初始化 echarts, echarts.init(dom);我这里是存起来，方便其他地方调用
      this.echart.chart = echarts.init(this.$refs["echarts"]);
      // 初始化数据
      const datas = this.echart.datas;
      // 图表的设置
      const option = {
        tooltip: {
          trigger: "axis",
        },
        calculable: true,
        grid: {
          top: 50,
          bottom: 40,
        },
        xAxis: [
          {
            type: "category",
            data: datas.time_quantum,
            axisLine: {
              lineStyle: {
                color: "#fff",
              },
            },
          },
        ],
        yAxis: [
          {
            type: "value",
            axisLine: {
              lineStyle: {
                color: "#fff",
              },
            },
          },
        ],
        series: [
          {
            type: "bar",
            name: "购入量",
            data: datas.quantity,
            markPoint: {
              data: [
                { type: "max", name: "最大值" },
                { type: "min", name: "最小值" },
              ],
            },
            markLine: {
              data: [{ type: "average", name: "平均值" }],
            },
          },
        ],
      };

      // 渲染图表
      if (option && typeof option === "object") {
        //这里用延迟是因为这个叼毛东西在VUE环境下有问题，需要延迟执行
        // setTimeout(() => {
        // 渲染图表 chart.setOption(option, Boolean), 第二个参数默认 false ,如果ture 就是全部重新弄，如果不填就是更新并渲染图表
        this.echart.chart.setOption(option, true);
        // }, 100);
      }
    },
    async getTeamReport() {
      await this.$axios.get("personal-center/team-report").then((res) => {
        if (res.code === 0) {
          if (res.data) this.echart.datas = res.data;
          const datas = this.echart.datas;
          this.echart.chart.setOption({
            xAxis: [
              {
                data: datas.time_quantum,
              },
            ],
            series: [
              {
                type: "bar",
                data: datas.quantity,
              },
            ],
          });
        } else {
          console.log(res.message);
          this.$message.console.error(res.message);
        }
      });
    },
  },
  created() {
    // （this）data、methods 已初始化，数据（data）、方法(methods) 尚未 编译成模版，只是存在内存中
    // console.log("created: \n");
    const intervalID = setInterval(() => {
      const dom = this.$refs["echarts"];
      if (dom && echarts) {
        this.echart_init();
        this.getTeamReport();
        // this.Echarts_KLineTrend();
        // this.getCandlestickData();
        clearInterval(intervalID);
      }
    }, 10);
  },
};
</script>

<style lang="less" scoped>
#echarts {
  > div {
    height: 4rem;
  }
}
</style>